import jwtDecode from 'jwt-decode';
import SuperFetch from './superFetch';

class AuthHelper {
  login = async userInfo => {
    if (!userInfo.email || !userInfo.password) {
      return { error: 'please fill in the input' };
    }
    return await SuperFetch.post('user/login', userInfo).then(response => {
      return this.checkExpirity(response);
    });
  };
  async checkDemoPage(token) {
    if (this.checkExpirity(token).error) {
      return { error: 'Token expired' };
    }
    return await SuperFetch.get('secret/test', { token })
      .then(response => ({
        status: '200',
        message: 'Success',
      }))
      .catch(error => ({ error: JSON.stringify(error) }));
  }
  checkExpirity = response => {
    let token = response && response.data ? response.data.token : null;
    if (!token) {
      if (response && response.errors && response.errors.confirmation) {
        return {
          error:
            response && response.errors.confirmation
              ? response.errors.confirmation[0]
              : 'Server error',
        };
      }
      if (response && response.errors && response.errors.email) {
        return {
          error:
            response && response.errors.email
              ? response.errors.email[0]
              : 'Server error',
        };
      }
      return {
        error: response ? response.message : 'Server error',
      };
    }
    try {
      let profileData = response.data;
      delete profileData.token;
      localStorage.setItem('profile', JSON.stringify(profileData));
      const profile = jwtDecode(token);

      const expiredAt = profile.expiredAt || profile.exp * 1000;

      if (expiredAt > new Date().getTime()) {
        return {
          ...profile,
          token,
          profileData,
          expiredAt: new Date(expiredAt),
        };
      } else {
        return { error: 'Token expired' };
      }
    } catch (e) {
      console.log(e);

      return { error: 'Server Error' };
    }
  };
}
export default new AuthHelper();
