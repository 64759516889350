let options = [
  {
    key: '',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-android-options',
  },
];
let profileOptions = [
  {
    key: 'profile',
    label: 'sidebar.profile',
    leftIcon: 'ion-android-person-add',
  },
];

let exporterOptions = [
  {
    key: 'store',
    label: 'sidebar.storeInfo',
    leftIcon: 'ion-android-home',
  },
  {
    key: 'subscriptions',
    label: 'sidebar.subscriptions',
    leftIcon: 'ion-card',
  },
  {
    key: 'subUsers',
    label: 'sidebar.subUsers',
    leftIcon: 'ion-android-people',
  },
  {
    key: 'products',
    label: 'sidebar.products',
    leftIcon: 'ion-android-cart',
    children: [
      {
        key: 'products',
        label: 'sidebar.allProducts',
      },
      {
        key: 'liveProducts',
        label: 'sidebar.liveProducts',
      },
    ],
  },
  {
    key: 'reviews',
    label: 'sidebar.productsReviews',
    leftIcon: 'ion-android-cart',
  },
  {
    key: 'rfq',
    label: 'sidebar.rfq',
    leftIcon: 'ion-pull-request',
  },
];

let importerOptions = [
  {
    key: 'requestedRfq',
    label: 'sidebar.requestedRfq',
    leftIcon: 'ion-pull-request',
    children: [
      {
        key: 'requestedRfq/RfqExporter',
        label: 'sidebar.RfqExporter',
      },
      {
        key: 'requestedRfq/RfqShipment',
        label: 'sidebar.RfqShipment',
      },
      {
        key: 'requestedRfq/RfqQuotation',
        label: 'sidebar.RfqQuotation',
      },
    ],
  },
  // {
  //   key: 'orders',
  //   label: 'sidebar.orders',
  //   leftIcon: 'ion-android-clipboard',
  //   children: [
  //     {
  //       key: 'orders/pending',
  //       label: 'sidebar.pendingOrders',
  //     },
  //     {
  //       key: 'orders/active',
  //       label: 'sidebar.activeOrders',
  //     },
  //     {
  //       key: 'orders/history',
  //       label: 'sidebar.historyOrders',
  //     },
  //   ],
  // },
];

let supUserOptions = [
  {
    key: 'products',
    label: 'sidebar.products',
    leftIcon: 'ion-android-cart',
    children: [
      {
        key: 'products',
        label: 'sidebar.allProducts',
      },
      {
        key: 'liveProducts',
        label: 'sidebar.liveProducts',
      },
    ],
  },
  {
    key: 'reviews',
    label: 'sidebar.productsReviews',
    leftIcon: 'ion-android-cart',
  },
  {
    key: 'rfq',
    label: 'sidebar.rfq',
    leftIcon: 'ion-pull-request',
  },
];

export {
  options,
  exporterOptions,
  importerOptions,
  supUserOptions,
  profileOptions,
};
