export default {
  //apiUrl: 'http://127.0.0.1:8000/api/',
  //apiUrl: 'https://dev-back.git-zone.com/api/',
  apiUrl: 'https://back.git-zone.com/api/'
};
const siteConfig = {
  siteName: 'ISOMORPHIC',
  siteIcon: 'ion-flash',
  footerText: 'GIT-ZONE.com ©2020',
};


const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'english';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: '',
};
const Auth0Config = {
  domain: '',
  clientID: '',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'en',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: 'https://git-zone.com/auth0loginCalldev-back',
    },
    languageDictionary: {
      title: 'Isomorphic',
      emailInputPlaceholder: 'demo@gmail.com',
      passwordInputPlaceholder: 'demodemo',
    },
    theme: {
      labeledSubmitButton: true,
      logo: '',
      primaryColor: '#E14615',
      authButtons: {
        connectionName: {
          displayName: 'Log In',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000',
        },
      },
    },
  },
};
const firebaseConfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: '',
};
const googleConfig = {
  apiKey: '', //
};
const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: [],
};
const youtubeSearchApi = '';
// const apiUrl = 'https://git-zone.com/api/';
// const apiUrl = 'https://git-zone.appssquare.com/api/';
/* live*/
const apiUrl = 'https://back.git-zone.com/api/';
const dev_apiUrl = 'https://git-zone.com/api/';
const frontUrl = 'https://git-zone.com/';

/* dev
const apiUrl = 'https://dev-back.git-zone.com/api/';
const dev_apiUrl = 'https://dev-back.git-zone.com/api/';
const frontUrl = 'https://dev.git-zone.com';
*/
/* local
const apiUrl = 'http://127.0.0.1:8000/api/';
const dev_apiUrl = 'http://127.0.0.1:8000/api/';
const frontUrl = 'http://localhost:3000/';
*/
const accountKitConfig = {
  appId: '',
  state: 'csrf',
  version: 'v1.0',
  fbAppEventsEnabled: true,
  redirect: '',
  debug: process.env.NODE_ENV !== 'production',
};

export {
  siteConfig,
  themeConfig,
  apiUrl,
  dev_apiUrl,
  language,
  frontUrl,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi,
  accountKitConfig,
};
