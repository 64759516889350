import { getDefaultPath } from '../../helpers/urlSync';
import actions, { getView } from './actions';

const preKeys = getDefaultPath();
const profileData = JSON.parse(localStorage.getItem('profile'));
let userRoles = {
  exporter: false,
  importer: false,
  investor: false,
};
if (profileData) {
  profileData.roles.map(function(key, index) {
    if (key.name === 'exporter') {
      userRoles.exporter = true;
    }
    if (key.name === 'importer') {
      userRoles.importer = true;
    }
    if (key.name === 'investor') {
      userRoles.importer = true;
    }
    return userRoles;
  });
}
let multiRole = false;
if (userRoles.exporter && userRoles.importer) {
  multiRole = true;
}

const initState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  profileData: false,
  approved: profileData && profileData.approved !== '1' ? false : true,
  uploadedDocs: profileData && profileData.docs_uploaded ? true : false,
  subscribed: profileData && profileData.subscription ? true : false,
  redirectedProfile: false,
  selectedRole: multiRole
    ? 'exporter'
    : localStorage.getItem('main_role')
    ? localStorage.getItem('main_role')
    : 'importer',
  multiRole: multiRole,
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer,
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height,
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys,
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current,
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: [],
      };
    case actions.SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.selectedRole,
      };
    case actions.UPLOADED_DOCS:
      return {
        ...state,
        uploadedDocs: action.uploadedDocs,
      };
    case actions.IS_SUBSCRIBED:
      return {
        ...state,
        subscribed: action.subscribed,
      };
    case actions.IS_APPROVED:
      return {
        ...state,
        approved: action.approved,
      };
    case actions.MULI_ROLES:
      return {
        ...state,
        multiRole: action.multiRole,
      };
    case actions.PROFILE_DATA:
      return {
        ...state,
        profileData: action.profileData,
      };
    case actions.REDIRECTED_PROFILE:
      return {
        ...state,
        redirectedProfile: action.redirectedProfile,
      };
    default:
      return state;
  }
  return state;
}
