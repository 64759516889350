export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}
const actions = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  UPLOADED_DOCS: 'UPLOADED_DOCS',
  IS_APPROVED: 'IS_APPROVED',
  IS_SUBSCRIBED: 'IS_SUBSCRIBED',
  REDIRECTED_PROFILE: 'REDIRECTED_PROFILE',
  MULI_ROLES: 'MULI_ROLES',
  SELECTED_ROLE: 'SELECTED_ROLE',
  CLEAR_MENU: 'CLEAR_MENU',
  PROFILE_DATA: 'PROFILE_DATA',
  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  selectedRole: selectedRole => ({
    type: actions.SELECTED_ROLE,
    selectedRole,
  }),
  multiRole: multiRole => ({
    type: actions.MULI_ROLES,
    multiRole,
  }),
  approved: approved => ({
    type: actions.IS_APPROVED,
    approved,
  }),
  subscribed: subscribed => ({
    type: actions.IS_SUBSCRIBED,
    subscribed,
  }),
  uploadedDocs: uploadedDocs => ({
    type: actions.UPLOADED_DOCS,
    uploadedDocs,
  }),
  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  redirectedProfile: current => ({
    type: actions.REDIRECTED_PROFILE,
    current,
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  profileData: profileData => ({
    type: actions.PROFILE_DATA,
    profileData,
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU }),
};
export default actions;
