import styled from 'styled-components';
import { palette } from 'styled-theme';

const AppHolder = styled.div`
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette('secondary', 1)};

    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }

  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
    }
  }

  .ant-layout-footer {
    font-size: 13px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  button {
    border-radius: 0;
  }
  .isoLeft {
    color: white;
  }
  li.ant-menu-item a {
    color: white !important;
  }
  header.isomorphicTopbar.ant-layout-header {
    background-color: #e0364c;
  }
  button.triggerBtn.menuOpen {
    color: white !important;
  }
  span.isoMenuHolder {
    color: white !important;
  }
  section.isoContactBoxWrapper.ant-layout {
    background-color: white;
  }
  .isoContactCard {
    margin-top: 20px;
  }
  button.ant-btn.ant-btn-primary {
    background-color: #e0364c;
    border-color: #9d2635;
  }
  .collapsedImage {
    width: 30px;
  }
  .unCollapsedImage {
    height: 60px;
  }
  .dashMain {
    background-color: #fff;
    height: 800px;
  }

  input.ant-input {
    background-color: #eeeeee !important;
    color: #980000 !important;
  }
  .uploadDocs .ant-col.ant-form-item-control-wrapper {
    padding: 10px;
    text-align: center;
    background-color: #eee;
  }
  .isoLoginContent .isoSignInForm button {
    font-weight: 500;
    background-color: #bd1723;
    color: white;
  }
  td.status-col {
    text-transform: capitalize;
  }
  td.status-actions button {
    margin: 2px;
    display: block;
    width: 99px;
  }
  td.ant-descriptions-item-label {
    text-transform: capitalize;
  }
  .reply-form {
    padding: 25px !important;
  }
  .plans-view {
    width: 75%;
  }
  .isoSignInForm.plans-container {
    width: 100%;
  }
  .plans-view li span {
    font-size: 15px;
  }
  .pricing-header {
    background-color: #bd1723;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
  }
  .pricing-header h2 {
    color: white;
  }
  .pricing-header p {
    color: white;
  }
  .subscribe-button {
    border-radius: 10px;
    text-align: center;
  }
  .price_card.selected-plan {
    box-shadow: 0 0 8px 3px rgb(255, 82, 105);
  }
  button.plans-button {
    color: #fff;
    border-radius: 5px;
    background-color: #e0364c;
  }

  button.plans-button:hover {
    color: #fff;
    background-color: #960013;
  }
  button.disabled-button {
    background-color: gray !important;
  }
  a.ant-btn.ant-btn-primary {
    font-weight: 500;
    background-color: #bd1723;
    color: white;
    border-color: #bf000e;
  }
`;

export default AppHolder;
