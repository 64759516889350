import Auth from './auth/reducer';
import App from './app/reducer';
import Contacts from './contacts/reducer';
import DynamicChartComponent from './dynamicEchart/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import modal from './modal/reducer';

function lastAction(state = null, action) {
  return action;
}
export default {
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Contacts,
  DynamicChartComponent,
  modal,
  lastAction,
};
