import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, LocaleProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import { siteConfig } from '../../settings';
import { AppLocale } from '../../dashApp';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import qs from 'qs';
import history from '../../helpers/history';
import SuperFetch from '../../helpers/superFetch';
import './global.css';
import { Redirect } from 'react-router-dom';
const { Content, Footer } = Layout;
const { logout } = authAction;
const {
  toggleAll,
  approved,
  selectedRole,
  multiRole,
  subscribed,
  profileData,
  uploadedDocs,
} = appActions;
export class App extends Component {
  state = {
    isSocial: false,
    confirmError: false,
    redirectToLogged: false,
  };
  getProfile = () => {
    return SuperFetch.get('profile').then((response) => {
      this.setState({ loading: false });

      if (!response.status) {
        history.replace('/');
      } else {
        localStorage.setItem('profile', JSON.stringify(response.data));
        this.props.profileData(response.data);
        if (response.data.confirmed) {
          this.setState({ redirectToReferrer: true });
        } else {
          this.setState({ redirectToLogged: localStorage.getItem('id_token') });
          localStorage.setItem('social', 1);
        }
      }
    });
  };

  render() {
    const { url } = this.props.match;
    const { locale, selectedTheme, height } = this.props;
    const { confirmError } = this.state;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;
    if (confirmError) {
      console.log(2222);
      localStorage.clear();
      return <Redirect to='/signin' />;
    }
    let token = this.props.match.params.token;

    if (token && !this.state.isSocial) {
      localStorage.setItem('id_token', token);
      this.setState({ isSocial: true });
      this.getProfile();
    }

    if (this.state.redirectToReferrer) {
      return <Redirect to='/' />;
    }
    if (this.state.redirectToLogged) {
      return (
        <Redirect to={`/register/continue/${this.state.redirectToLogged}`} />
      );
    }

    let id_token = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).token;
    if (id_token) {
      localStorage.setItem('id_token', id_token);
      SuperFetch.get('profile').then((response) => {
        if (response.status === 'success') {
          localStorage.setItem('profile', JSON.stringify(response.data));
          return <Redirect to='/' />;
        } else {
          localStorage.clear();
          return <Redirect to='/signin' />;
        }
      });
    }
    if (localStorage.getItem('id_token')) {
      SuperFetch.get('profile').then((response) => {
        localStorage.setItem('unites', JSON.stringify(response.data.unites));
        if (response.status === 'success') {
          if (!this.props.profile) {
            this.props.profileData(response.data);
          }

          if (!response.data.confirmed) {
            localStorage.clear();
            return <Redirect to='/signin' />;
          }
          if (response.data.approved !== '1') {
            this.props.approved(false);
          } else {
            this.props.approved(true);
          }
          if (response.data.subscription) {
            this.props.subscribed(true);
          } else {
            this.props.subscribed(false);
          }

          // if (response.data.docs_uploaded) {
          //   this.props.uploadedDocs(true);
          // } else {
          //   this.props.uploadedDocs(false);
          // }
          let responseSelectedRole = false;
          let responseMultiRole = false;
          if (response.data) {
            response.data.roles.map(function(key, index) {
              if (key.name === 'importer') {
                responseSelectedRole = 'importer';
              }
              if (key.name === 'exporter') {
                if (responseSelectedRole == 'importer') {
                  responseMultiRole = true;
                }
                responseSelectedRole = 'exporter';
              }
              if (key.name === 'investor') {
                responseSelectedRole = 'investor';
              }
              if (key.name === 'sub-user') {
                responseSelectedRole = 'sub-user';
              }
              return responseSelectedRole;
            });
          }
          selectedRole(responseSelectedRole);
          multiRole(responseMultiRole);
          if (!localStorage.getItem('main_role')) {
            localStorage.setItem('main_role', responseSelectedRole);
          }
          localStorage.setItem('profile', JSON.stringify(response.data));
        } else {
          localStorage.clear();
          return <Redirect to='/signin' />;
        }
      });
    }

    // console.log(qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id
    // );

    if (
      this.props.location.pathname.search('register/continue') === 1 &&
      !localStorage.getItem('social')
    ) {
      // localStorage.clear();
      // return <Redirect to='/signin' />;
    }

    if (this.props.location.pathname.search('register/continue') === 1) {
      return false;
    }

    if (this.props.location.pathname.search('password/reset') === 1) {
      return false;
    }
    if (this.props.location.pathname.search('dashboard/profile/TOKEN') === 1) {
      return false;
    }

    if (this.props.location.pathname.search('register/confirm') === 1) {
      return false;
    }

    if (!localStorage.getItem('id_token')) {
      if (this.props.location.hash === '#forgetpassword') {
        return <Redirect to='/signin#forgetpassword' />;
      }
      if (this.props.location.hash === '#reg') {
        return <Redirect to='/signin#reg' />;
      }
      return <Redirect to='/signin' />;
    }

    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time='1000' handler='onResize'>
                  <WindowResizeListener
                    onResize={(windowSize) =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight,
                      )
                    }
                  />
                </Debounce>
                <Topbar url={url} />
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                  <Sidebar url={url} />
                  <Layout
                    className='isoContentMainLayout'
                    style={{
                      height: height,
                    }}
                  >
                    <Content
                      className='isomorphicContent'
                      style={{
                        padding: '70px 0 0',
                        flexShrink: '0',
                        background: '#f1f3f6',
                        position: 'relative',
                      }}
                    >
                      <AppRouter url={url} />
                    </Content>
                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid #ededed',
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.Auth,
    isSelectedRole: state.App.selectedRole,
    isMultiRole: state.App.multiRole,
    isApproved: state.App.approved,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
    profile: state.App.profileData,
    height: state.App.height,
  }),
  {
    logout,
    toggleAll,
    approved,
    selectedRole,
    multiRole,
    subscribed,
    uploadedDocs,
    profileData,
  },
)(App);
