import { apiUrl,dev_apiUrl } from '../settings';
const customHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('id_token') ,
  'X-Platform': 'dashboard',
  'X-Language': 'en',
});
const base = (method, url, data = {}) => {
  if (method !== 'get') {
    return fetch(`${apiUrl}${url}`, {
      method,
      headers: customHeader(),
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(res => res)
      .catch(error => ({ error: 'Server Error' }));
  } else {
    return fetch(`${apiUrl}${url}`, {
      method,
      headers: customHeader() 
    })
      .then(response => response.json())
      .then(res => res)
      .catch(error => ({ error: 'Server Error' }));
  }

};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
