import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import appActions from '../app/actions';
import { setToken, clearToken, getToken } from '../../helpers/utility';
import AuthHelper from '../../helpers/authHelper';
import notification from '@isomorphic/shared/isomorphic/components/Notification';
import { frontUrl } from '../../settings';
export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const { history, userInfo } = payload;
    const result = yield call(AuthHelper.login, userInfo);
    console.log(result);
    localStorage.setItem('unites', JSON.stringify(result.profileData.unites));
    if (result.token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: result,
        token: result.token,
        history,
      });
      let uploadedDocs = true;
      let selectedRole = false;
      let multiRole = false;
      if (result.profileData) {
        result.profileData.roles.map(function(key, index) {
          if (key.name === 'importer') {
            selectedRole = 'importer';
          }
          if (key.name === 'exporter') {
            if (selectedRole == 'importer') {
              multiRole = true;
            }
            selectedRole = 'exporter';
          }
          if (key.name === 'investor') {
            selectedRole = 'investor';
          }
          if (key.name === 'sub-user') {
            selectedRole = 'sub-user';
          }
          return selectedRole;
        });
        if (result.profileData.docs_uploaded) {
          uploadedDocs = true;
        } else {
          uploadedDocs = false;
        }
      }
      yield put({
        type: appActions.SELECTED_ROLE,
        payload: result,
        selectedRole: selectedRole,
        history,
      });

      yield put({
        type: appActions.UPLOADED_DOCS,
        payload: result,
        uploadedDocs: uploadedDocs,
        history,
      });

      yield put({
        type: appActions.MULI_ROLES,
        payload: result,
        multiRole: multiRole,
        history,
      });
    } else {
      console.log(result);
      notification('error', result.error || result);
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*({ payload, history }) {
    yield setToken(payload.token);
    if (history) {
      history.push('/');
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    localStorage.clear();
    window.location.replace(frontUrl + '?logout=true');
    yield put(push('/'));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const { token } = AuthHelper.checkExpirity(getToken());
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: { token },
        token,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
