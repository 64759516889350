import actions from './actions';

const initState = { idToken: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return { ...state, idToken: action.token };
    case actions.LOGIN_REQUEST:
      return { ...state, idToken: action };
    case actions.SOCIAL:
      return { ...state, social: action.social };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
