import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Button } from 'antd';
import appActions from '../../redux/app/actions';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';
import { Redirect, browserHistory } from 'react-router-dom';
import SuperFetch from '../../helpers/superFetch';
import {
  messageSuccess,
  messageError,
} from '@isomorphic/shared/isomorphic/components/Message';
const { Header } = Layout;
const { toggleCollapsed, selectedRole, multiRole } = appActions;
const ButtonGroup = Button.Group;

class Topbar extends Component {
  state = {
    gotoSubscription: false,
  };
  render() {
    const {
      toggleCollapsed,
      url,
      customizedTheme,
      locale,
      selectedRole,
      getSelectedRole,
      isMultiRole,
    } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70,
    };
    const setMainType = (val) => {
      localStorage.setItem('main_role', val);
      selectedRole(val);
    };

    const setSubscribeType = (val) => {
      if (val === 'importer') {
        console.log(1111);
        SuperFetch.post('type/importer', []).then((response) => {
          if (response.success) {
            selectedRole('importer');
            multiRole(true);
            messageSuccess(
              'You have been registered as importer please go to your profile and upload the importer card!',
            );
          } else {
            messageError(response.message);
          }
        });
      }
    };
    if (this.state.gotoSubscription) {
      return <Redirect push={true} to='/subscriptions' />;
    }
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          <div className='isoLeft'>
            <button
              className={
                collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
          </div>

          <ul className='isoRight'>
            {isMultiRole && (
              <li className='isoSearch'>
                <ButtonGroup>
                  <Button
                    onClick={(e) => setMainType('importer')}
                    type={getSelectedRole === 'importer' ? 'danger' : false}
                  >
                    Importer
                  </Button>
                  <Button
                    onClick={(e) => setMainType('exporter')}
                    type={getSelectedRole === 'exporter' ? 'danger' : false}
                  >
                    Exporter
                  </Button>
                </ButtonGroup>
              </li>
            )}

            {!isMultiRole && getSelectedRole == 'exporter' && (
              <li className='isoSearch'>
                <ButtonGroup>
                  <Button onClick={(e) => setSubscribeType('importer')}>
                    Register as importer
                  </Button>
                </ButtonGroup>
              </li>
            )}

            {!isMultiRole && getSelectedRole == 'importer' && (
              <li className='isoSearch'>
                <ButtonGroup>
                  <a className='ant-btn' href='/subscriptions'>
                    Subscribe as Exporter
                  </a>
                </ButtonGroup>
              </li>
            )}
            {/* <li className="isoSearch">
              <TopbarSearch locale={locale} />
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "notification" })}
              className="isoNotify"
            >
              <TopbarNotification locale={locale} />
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "message" })}
              className="isoMsg"
            >
              <TopbarMessage locale={locale} />
            </li>
            <li
              onClick={() => this.setState({ selectedItem: "addToCart" })}
              className="isoCart"
            >
              <TopbarAddtoCart url={url} locale={locale} />
            </li> */}

            <li
              onClick={() => this.setState({ selectedItem: 'user' })}
              className='isoUser'
            >
              <TopbarUser locale={locale} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
    getSelectedRole: state.App.selectedRole,
    isMultiRole: state.App.multiRole,
  }),
  { toggleCollapsed, selectedRole, multiRole },
)(Topbar);
